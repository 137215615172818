<template>
    <div v-if="dataSource">
        <v-dialog 
            v-model="visible" 
            transition="dialog-top-transition" 
            scrollable 
            persistent 
            max-width="600"
        >
            <v-card class="modal-maincard">
                <v-toolbar dark flat dense>
                    <v-toolbar-title>{{ $t(title) }}</v-toolbar-title>
                </v-toolbar>
                <v-card-text class="wrapperFormModal">
                    <v-card 
                    flat
                    >
                        <v-card-text>
                            <v-form v-model="isFormValid">
                                <v-row no-gutters>
                                    <v-col cols="12" sm="12" md="3">
                                        <label class="f-label">{{ $t('Параметры_номера') }}</label>
                                    </v-col>
                                    <v-col  cols="12" sm="12" md="9">
                                        
                                        <v-radio-group
                                            v-model="counterType"
                                            hide-details
                                            dense
                                        >
                                            <v-radio
                                                :label="$t('Номер_по_порядку')"
                                                :value="1"
                                            ></v-radio>
                                            <v-radio 
                                                v-if="hasReservedNumber"                                               
                                                :value="2"
                                            >
                                                <template v-slot:label>
                                                    <v-col
                                                        cols="4" sm="4" md="4"
                                                        class="ma-0 pa-0"
                                                    >
                                                        {{ $t('Из_резерва') }}
                                                    </v-col>
                                                    <v-col
                                                        cols="8" sm="8" md="8"
                                                        class="ma-0 pa-0"
                                                    >
                                                        <v-select                                                                                                         
                                                            v-model="reserveItem"                                                                                                                        
                                                            v-show="counterType==2"
                                                            :items="reservedItems"
                                                            item-text="Template"
                                                            return-object
                                                            hide-details
                                                            required 
                                                            outlined 
                                                            dense
                                                            append-icon="fas fa-chevron-down"
                                                            :menu-props="{ bottom: true, offsetY: true }"
                                                        >
                                                        </v-select>
                                                    </v-col>
                                                </template>
                                            </v-radio>
                                        </v-radio-group>
                                            
                                                    
                                                
                                    </v-col>
                                </v-row>
                                <v-row no-gutters>
                                    <v-col cols="12" sm="12" md="3">
                                        <label class="f-label">{{ $t("Номер_регистрации") }}</label>
                                    </v-col>
                                    <v-col  cols="12" sm="12" md="9">
                                        <div
                                            class="onlyReadData"
                                        >
                                            <div v-if="registerParam.PreparedNumber && registerParam.PreparedNumber.length > 0">
                                                {{registerParam.PreparedNumber}}
                                                - <i>{{ $t("предварительный") }}</i>
                                            </div>
                                            <div v-else>
                                                <i>{{ $t("Не_определено").toLowerCase() }}</i>   
                                            </div>    
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-form>
                            
                        </v-card-text>
                    </v-card>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn 
                    color="cyan"
                    text
                    depressed
                    @click="ok" 
                    v-if="isFormValid">{{ $t('Зарегистрировать') }}</v-btn>
                    <v-btn
                    color="blue-grey" 
                    text
                    depressed
                    @click="cancel">{{ $t('Отмена') }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
    data() {
        return {
            options: {
                color: 'grey lighten-3',
                width: 400,
                zIndex: 200,
                noconfirm: false,
            },
        };
    },
    computed: {
        ...mapGetters('dialogs/cscRegistry', { visible: 'getVisible', registerParam: 'getRegisterParam', counterType: 'getCounterType' }),
        ...mapGetters('actionsource', { dataSource: 'getDataSource' }),

        title() {
            return `Регистрация_заявки`;
        },
        hasReservedNumber() {
            return this.registerParam.SelectedCounter?.CounterReserve?.Items?.length > 0
        },
        reservedItems() {
            return this.registerParam.SelectedCounter?.CounterReserve?.Items ?? ['Нет_зарезервированных_номеров'];
        },
        reserveItem: {
            get: function() {
                return this.$store.getters['dialogs/cscRegistry/getReserveItem'];
            },
            set: function(v) {
                this.$store.dispatch('dialogs/cscRegistry/setReserveItem', v);
            }
        },
        isFormValid: {
            get: function() {
                return this.$store.getters['dialogs/cscRegistry/getIsFormValid'];
            },
            set: function(v) {
                this.$store.dispatch('dialogs/cscRegistry/setIsFormValid', v);
            }
        },
    },

    methods: {
        ...mapActions('dialogs/cscRegistry', ['ok', 'cancel']),
    },
};
</script>

<style lang="scss" scoped>
.error-message
{
    font-style: italic;
    color: #a94442;
    line-height: 1;
}
</style>